<template>
  <div>
    <b-card-code title="Add Pricing" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group label="Price" label-for="Price">
                <validation-provider
                  #default="{ errors }"
                  name="Price"
                  rules="required"
                >
                  <b-form-input
                    id="Price"
                    v-model="data.price"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              v-show="available_amount != null"
              class="d-flex justify-content-start align-items-center"
            >
              <p>Aviable: {{ available_amount }} EGP</p>
            </b-col>
            <b-col md="6">
              <b-form-group label="Note" label-for="Note">
                <validation-provider
                  #default="{ errors }"
                  name="Note"
                  rules="required"
                >
                  <b-form-input
                    id="Note"
                    v-model="data.note"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
  },
  
  mixins: [heightTransition],
  watch: {
    vendorSelect: function () {
      if (this.vendorSelect != null) {
        this.selectedVendor(this.vendorSelect.value)
      }
    },
  },
  data() {
    return {
      vendorOptions: [],
      vendorSelect: null,
      errors_back: [],
      available_amount: null,
      showDismissibleAlert: false,
      required,
      data: {
        vendor_id: '',
        price: '',
        type: 'manual',
        note: '',
      },
    }
  },
  created() {
    axios
      .get('payouts/' + this.$route.params.id)
      .then((result) => {
        const data = result.data.data
        this.data.vendor_id = data.vendor.id
        this.data.price = data.price
        this.selectedVendor(this.data.vendor_id)
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .put('payouts/' + this.$route.params.id + '/update', this.data)
            .then((result) => {
             
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    selectedVendor(vendorID) {
      axios
        .get('payouts/vendor/' + vendorID + '/available-amount/show')
        .then((result) => {
          const data = result.data.data
      
          this.available_amount = data.available_amount
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },
}
</script>

<style lang="scss">
 
</style>

<style lang="scss" scoped></style>

<style></style>
